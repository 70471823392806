<template>
  <header class="d-flex flex-wrap justify-content-around align-items-center  border-bottom">
    <img class="logo" src="../assets/logo-semfundo.png" alt="logo da escola" style="width: 10vw;">
    <nav class="navbar navbar-expand-lg">
      <!-- Botão de hambúrguer -->
      <button class="navbar-toggler" type="button" @click="toggleMenu" aria-controls="navbarNav"
        :aria-expanded="isMenuOpen.toString()" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Menu -->
      <div class="collapse navbar-collapse" :class="{'show': isMenuOpen}" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link active" href="#inicio">Início</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#sobre">Sobre</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#equipe">Equipe</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#projetos">Projetos</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#estrutura">Estrutura</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#contato">Contato</a>
          </li>
        </ul>
      </div>
    </nav>

    <div class="d-flex flex-direction-row gap-4">
      <a href="https://www.facebook.com/escolaturmadosaber1/?locale=pt_BR" target="_blank"><i
          class="bi bi-facebook"></i></a>
      <a href="https://wa.me/559186377974?text=Olá!%20Gostaria%20de%20mais%20informações%20sobre%20a%20ETS."
        target="_blank"><i class="bi bi-whatsapp"></i></a>
      <a href="https://www.instagram.com/escolaturmadosaber/" target="_blank"><i class="bi bi-instagram"></i></a>
    </div>
  </header>
  <main>
    <div id="sobre" class="about d-flex flex-row justify-content-xl-between align-items-center">
      <section>
        <p>
          Fundada em 26 de janeiro de 2009, a Escola Turma do Saber celebra em 2024 seus 15 anos
          de trajetória, dedicados a educar crianças e jovens com amor e compromisso. <br>
          A Turma do Saber atende desde o Ensino Infantil e Fundamental I e II até o Ensino em Tempo
          Integral, proporcionando um ambiente seguro e acolhedor para o desenvolvimento completo dos
          baixinhos. Além do ensino de qualidade, valorizamos o bem-estar e incentivamos o crescimento
          pessoal com projetos especiais, como o Projeto Poesia e o Projeto Higiene Bucal. Oferecemos
          também atividades enriquecedoras, como aulas de Ballet, Karatê e muito mais. <br>
          Nosso objetivo é formar cidadãos éticos e preparados para o futuro, sempre com atenção ao
          desenvolvimento integral e ao cuidado de cada aluno.
        </p>
      </section>
      <aside>
        <img class="fachada" src="../assets/fachada.jpeg" alt="ilustração de alunos ">
      </aside>
    </div>

    <div class="barra">
      <div class="background-1">
        <h3 class="d-flex justify-content-center">2025 Matriculas Abertas</h3>
        <h4>Infantil ao 9° Ano</h4>
      </div>
    </div>

    <div id="equipe" class="container-equipe border-bottom">
      <button class="btn-23">
        <span class="text">Equipe</span>
        <span aria-hidden="" class="marquee">Equipe</span>
      </button>

      <div class="container p-5 d-flex flex-direction-row justify-content-around gap-2">
        <div class="card" style="width: 22rem;">
          <img class="card-img-top" src="../assets/adriele.png" alt="Card image cap" style="height: 55vh;">
          <div class="card-body text-center">
            <h5 class="card-title">Adriele Carvalho</h5>
          </div>
        </div>

        <div class="card" style="width: 22rem;">
          <img class="card-img-top" src="../assets/daniela.png" alt="Card image cap" style="height: 55vh;">
          <div class="card-body text-center">
            <h5 class="card-title">Daniela Oliveira</h5>
          </div>
        </div>

        <div class="card" style="width: 22rem;">
          <img class="card-img-top" src="../assets/helinton.png" alt="Card image cap" style="height: 55vh;">
          <div class="card-body text-center">
            <h5 class="card-title">Helinton Ribeiro</h5>
          </div>
        </div>

        <div class="card" style="width: 22rem;">
          <img class="card-img-top" src="../assets/jessica.png" alt="Card image cap" style="height: 55vh;">
          <div class="card-body text-center">
            <h5 class="card-title">Jéssica Franco</h5>
          </div>
        </div>

      </div>

      <div class="container p-5 d-flex flex-direction-row justify-content-around gap-2">
        <div class="card" style="width: 22rem;">
          <img class="card-img-top" src="../assets/raniely.png" alt="Card image cap" style="height: 55vh;">
          <div class="card-body text-center">
            <h5 class="card-title">Raniely Santos</h5>
          </div>
        </div>

        <div class="card" style="width: 22rem;">
          <img class="card-img-top" src="../assets/adna.png" alt="Card image cap" style="height: 55vh;">
          <div class="card-body text-center">
            <h5 class="card-title">Adna Raquel</h5>
          </div>
        </div>

        <div class="card" style="width: 22rem;">
          <img class="card-img-top" src="../assets/jorlene.png" alt="Card image cap" style="height: 55vh;">
          <div class="card-body text-center">
            <h5 class="card-title">Jorlene Medonça</h5>
          </div>
        </div>

        <div class="card" style="width: 22rem;">
          <img class="card-img-top" src="../assets/rosilene.png" alt="Card image cap" style="height: 55vh;">
          <div class="card-body text-center">
            <h5 class="card-title">Rosilene Teixeira</h5>
          </div>
        </div>

      </div>

      <div class="container p-5 d-flex flex-direction-row justify-content-around gap-2">
        <div class="card" style="width: 22rem;">
          <img class="card-img-top" src="../assets/benedita.png" alt="Card image cap" style="height: 55vh;">
          <div class="card-body text-center">
            <h5 class="card-title">Maria Benedita</h5>
          </div>
        </div>

        <div class="card" style="width: 22rem;">
          <img class="card-img-top" src="../assets/marcos.png" alt="Card image cap" style="height: 55vh;">
          <div class="card-body text-center">
            <h5 class="card-title">Marcos Rodrigues</h5>
          </div>
        </div>

        <div class="card" style="width: 22rem;">
          <img class="card-img-top" src="../assets/coordena.png" alt="Card image cap" style="height: 55vh;">
          <div class="card-body text-center">
            <h5 class="card-title">Gisele Macedo</h5>
          </div>
        </div>
      </div>


    </div>

    <div id="projetos" class="container-estrutura">
      <button class="btn-23" style="background-color: var(--accent-red);">
        <span class="text">Projetos</span>
        <span aria-hidden="" class="marquee">Projetos</span>
      </button>
      <div class="estrutura d-flex flex-row justify-content-xl-between align-items-center" style="padding: 4vw;">
        <section>
          <h1>Projeto Esporte - Jiu-Jítsu</h1>
          <p>
            Mais do que uma arte marcial, o jiu-jítsu ensina lições valiosas que eles
            levarão para a vida toda. Quando uma criança pratica jiu-jítsu, ela não
            aprende apenas golpes e técnicas de autodefesa; ela desenvolve autoconfiança,
            disciplina, respeito e controle emocional. <br><br>
            Além disso, o jiu-jítsu é uma prática divertida e desafiadora, que ensina o
            respeito mútuo e a importância de trabalhar em equipe, algo essencial para o
            convívio em sociedade. No tatame, eles aprendem a respeitar o adversário,
            a lidar com frustrações e a nunca desistir. Isso fortalece o caráter e
            transforma as crianças em jovens mais fortes e responsáveis.
          </p>
        </section>
        <aside>
          <img src="../assets/jiujitsu.png" alt="Ilustração de aulas de jiu-jítsu">
        </aside>
      </div>

      <div class="estrutura d-flex flex-row justify-content-xl-between align-items-center" style="padding: 4vw;">
        <aside>
          <img style="margin-left: 0; margin-right: 10%;" src="../assets/ballet.png"
            alt="Ilustração de aulas de ballet">
        </aside>
        <section>
          <h1>Projeto Arte - Ballet</h1>
          <p>
            Muito além de uma dança, o ballet é uma escola de valores e aprendizados que
            eles levarão para sempre. Cada passo, cada movimento ensina muito mais do que
            a arte da dança: ensina dedicação, autoconfiança, disciplina e resiliência. <br><br>
            No ballet, as crianças encontram um espaço para desenvolver a expressividade,
            o equilíbrio e a postura, além de fortalecer o corpo e a mente. As aulas
            promovem a superação de limites e incentivam a paciência e o foco, habilidades
            que serão valiosas em todos os momentos da vida. E cada conquista, por menor que
            seja, aumenta a autoestima e o senso de responsabilidade.
          </p>
        </section>
      </div>

      <div class="estrutura d-flex flex-row justify-content-xl-between align-items-center" style="padding: 4vw;">
        <section>
          <h1>Projeto Viva a vida com Deus</h1>
          <p>
            Sabemos que o caminho da adolescência pode trazer desafios, e o nosso projeto
            “Setembro Amarelo com Deus” foi pensado para ser uma luz de acolhimento e
            esperança. <br><br>
            Ao decorrer do projeto, nossos alunos participam de atividades que promovem o
            autoconhecimento, a empatia e o fortalecimento emocional, sempre guiados
            pelos valores cristãos. Em momentos de diálogo e oração, incentivamos cada
            um deles a compartilhar suas angústias e a reconhecer que em Deus encontram
            um Pai que os ama e valoriza cada um de seus sonhos.
          </p>
        </section>
        <aside>
          <img src="../assets/setembro-amarelo.png" alt="Ilustração de aulas de jiu-jítsu">
        </aside>
      </div>
    </div>

    <div class="barra">
      <div class="background-2">
        <h3 class="d-flex justify-content-center" style="font-size: xx-large; padding-top: 1vh">Educação com amor e
          valores cristãos para
          um futuro brilhante. Junte-se à Turma do Saber!</h3>
      </div>
    </div>

    <div id="estrutura" class="container-projetos">
      <button class="btn-23">
        <span class="text">Estrutura</span>
        <span aria-hidden="" class="marquee">Estrutura</span>
      </button>

      <div class="container d-flex flex-direction-row justify-content-center gap-5"
        style="padding-top: 3vh; padding-bottom: 10vh;">
        <img class="img-estrutura rounded" src="../assets/sala.jpeg" alt="foto da sala de aula">
        <img class="img-estrutura rounded" src="../assets/lazer.jpeg" alt="foto área de lazer">
      </div>

      <div class="container d-flex flex-direction-row justify-content-center gap-5"
        style="padding-top: 3vh; padding-bottom: 10vh;">
        <img class="img-estrutura rounded" src="../assets/laboratorio.jpeg" alt="foto do laboratorio">
        <img class="img-estrutura rounded" src="../assets/biblioteca.jpeg" alt="foto área da biblioteca">
      </div>

      <div class="container d-flex flex-direction-row justify-content-center gap-5"
        style="padding-top: 3vh; padding-bottom: 10vh;">
        <img class="img-estrutura-maior rounded" src="../assets/devocional.jpeg" alt="foto do devocional">
        <img class="img-estrutura-maior rounded" src="../assets/sala 2.png" alt="foto de sala">
      </div>
    </div>

    <div class="container-valores">
      <div class="card border-secondary" style="max-width: 25rem; height: 50vh;">
        <h3 class="card-header">Valores</h3>
        <div class="card-body text-secondary">
          <p class="card-text text-center">Promover uma educação cristã de qualidade reconhecimento que a bíblia
            é a palavra de Deus, educando nossos alunos com dignidade e amor para um futuro melhor.</p>
        </div>
      </div>

      <div class="card border-secondary" style="max-width: 25rem; height: 50vh;">
        <h3 class="card-header">Missão</h3>
        <div class="card-body text-secondary">
          <p class="card-text text-center">Acreditar que a educação desempenha um papel essencial para o desenvolvimento humano.</p>
          <p class="card-text text-center">Ser referência em educação, estimular o pensamento próprio de cada aluno e cada profissional
            envolvido no processo educacional.</p>
          <p class="card-text text-center">Repassar princípios cristãos e valores bíblicos aos nossos alunos.</p>
        </div>
      </div>

      <div class="card border-secondary" style="max-width: 25rem; height: 50vh;">
        <h3 class="card-header">Visão</h3>
        <div class="card-body text-secondary">
          <p class="card-text text-center">Promover o conhecimento e os valores entre alunos e colaboradoress baseando - se nos 
            princípios cristãos.</p>
          <p class="card-text text-center">Ter compromisso e valorização dos ensinamentos cristãos.</p>
          <p class="card-text text-center">Almejamos potencializar habilidades e compotências na formação de nossos alunos para que encontrem
            seu propósito na vida.</p>
        </div>
      </div>
    </div>

    <div id="contato" class="container-contato">
      <button class="btn-23" style="background-color: var(--accent-red);">
        <span class="text">Contato</span>
        <span aria-hidden="" class="marquee">Contato</span>
      </button>
      <div class="container d-flex flex-direction-row gap-5 p-4 respon">
        <form class="form" action="https://formsubmit.co/escolaturmadosaber01@gmail.com" method="POST">
          <h3>Venha trazer seu filho para fazer parte da Turma!!</h3>
          <input class="form-control" type="text" placeholder="Nome" required name="name">
          <input class="form-control" type="text" placeholder="E-mail" required name="email" />
          <textarea class="form-control" rows="6" name="description" id="description" placeholder="Dúvidas e sugestões"
            required></textarea>
          <button class="btn-enviar" type="submit">
            <span><i class="icon bi bi-envelope"></i></span>
            <p class="text-enviar">Enviar</p>
          </button>
          <input type="hidden" name="_next" value="https://www.escolaturmadosaber.com" />
          <input type="hidden" name="_captcha" value="false" />
          <input type="hidden" name="_subject" value="Novo Contato!" />
          <input type="text" name="_honey" style="display: none" />
        </form>
        <aside>
          <img src="../assets/matricula.jpeg" alt="Ilustração de aulas de jiu-jítsu"
            style="width: 35vw; margin: 0; height: 70vh">
        </aside>
      </div>
    </div>
  </main>

  <footer class="footer d-flex flex-wrap justify-content-between align-items-center p-4 border-top">
    <h3>
      @2024 Turma do Saber
    </h3>
    <p style="position: absolute; left: 42vw; margin-top: 5vh;">
      <a href="https://portfolio-solon.vercel.app" target="_blank" style="font-size: large">Developed by Solon.Dev</a>
    </p>
    <div class="d-flex flex-direction-row gap-4">
      <a href="https://www.facebook.com/escolaturmadosaber1/?locale=pt_BR" target="_blank"><i
          class="bi bi-facebook"></i></a>
      <a href="https://wa.me/559186377974?text=Olá!%20Gostaria%20de%20mais%20informações%20sobre%20a%20ETS."
        target="_blank"><i class="bi bi-whatsapp"></i></a>
      <a href="https://www.instagram.com/escolaturmadosaber/" target="_blank"><i class="bi bi-instagram"></i></a>
    </div>
  </footer>

</template>

<script>
export default {
  name: "HomePage",
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>

<style scoped>
header {
  background-color: #fbff02;
  padding: 2vh 0;
}

a {
  font-size: 4vh;
  text-decoration: none;
  color: var(--primary-blue);
}

.bi-facebook {
  color: #3b5998;
}

.bi-whatsapp {
  color: #25d366;
}

.bi-instagram {
  background: linear-gradient(45deg, #833AB4, #E1306C, #F77737);
  -webkit-background-clip: text;
  color: transparent;
}

.navbar{
  --bs-navbar-active-color: var(--primary-blue) !important;
  --bs-navbar-brand-hover-color: var(--accent-red) !important;
}

.nav {
  --bs-nav-link-color: var(--primary-blue);
  --bs-nav-link-hover-color: var(--accent-red)
}

.nav-underline {
  --bs-nav-underline-link-active-color: var(--primary-blue);
}

.about {
  padding: 5%;
  height: 93.5vh;
  background-color: var(--secondary-blue);
  color: var(--white);
}

.estrutura {
  padding: 10%;
  color: var(--white);
}


section {
  flex: 1;
  position: relative;
}

aside {
  flex: 1;
  display: flex;
  justify-content: center;
}

section p {
  color: var(--white);
  font-weight: 600;
  font-size: medium;
  line-height: 1.8;
  font-family: 'Roboto', sans-serif;
}

.fachada{
  width: 50vw;
}

aside img {
  width: 100%;
  max-width: 100vh;
  height: auto;
  margin-left: 10%;
  border-radius: 10%;
}

.background-1,
.background-2 {
  height: 15vh;
  background-color: var(--light-gray);
  color: var(--primary-blue);
  padding-top: 3vh;
  font-size: 7vh;
  text-align: center;
}


.container-equipe {
  background-color: var(--medium-gray);
  text-align: center;
}

.card-title {
  color: var(--primary-blue);
}

.card-text-red {
  color: var(--accent-red);
}

.container-estrutura {
  background-color: var(--primary-blue);
  text-align: center;
}

.container-projetos {
  background-color: var(--accent-red);
  text-align: center;
}

.img-estrutura {
  height: 70vh;
  width: 50vw;
}

.img-estrutura-maior{
  height: 70vh;
  width: 40.5vw;
}

.container-contato {
  height: 90vh;
  background-color: var(--secondary-blue);
  padding: 4vh 0;
  text-align: center;
}

.container-valores{
  padding: 5vh 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 40vw;
  height: 70vh;
  gap: 2vh;
}

footer {
  background-color: var(--white);
}

/* reponsividade */

/* From Uiverse.io by doniaskima */
.btn-23,
.btn-23 *,
.btn-23 :after,
.btn-23 :before,
.btn-23:after,
.btn-23:before {
  border: 0 solid;
  box-sizing: border-box;
}

.btn-23 {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background-color: var(--primary-blue);
  background-image: none;
  color: #fff;
  cursor: pointer;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 100%;
  font-weight: 900;
  line-height: 1.5;
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  margin-top: 4vh;
  text-transform: uppercase;
}

.btn-23:disabled {
  cursor: default;
}

.btn-23:-moz-focusring {
  outline: auto;
}

.btn-23 svg {
  display: block;
  vertical-align: middle;
}

.btn-23 [hidden] {
  display: none;
}

.btn-23 {
  border-radius: 99rem;
  border-width: 2px;
  overflow: hidden;
  padding: 0.8rem 3rem;
  position: relative;
}

.btn-23 span {
  display: grid;
  inset: 0;
  place-items: center;
  position: absolute;
  transition: opacity 0.2s ease;
}

.btn-23 .marquee {
  --spacing: 7em;
  --start: 0em;
  --end: 7em;
  -webkit-animation: marquee 1s linear infinite;
  animation: marquee 1s linear infinite;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
  opacity: 0;
  position: relative;
  text-shadow: #fff var(--spacing) 0, #fff calc(var(--spacing) * -1) 0,
    #fff calc(var(--spacing) * -2) 0;
}

.btn-23:hover .marquee {
  -webkit-animation-play-state: running;
  animation-play-state: running;
  opacity: 1;
}

.btn-23:hover .text {
  opacity: 0;
}

@-webkit-keyframes marquee {
  0% {
    transform: translateX(var(--start));
  }

  to {
    transform: translateX(var(--end));
  }
}

@keyframes marquee {
  0% {
    transform: translateX(var(--start));
  }

  to {
    transform: translateX(var(--end));
  }
}

.btn-enviar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 20vw;
  height: 7vh;
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.349);
  background-color: rgb(12, 12, 12);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  color: #ecebee;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3vw;
  height: 6vh;
  background: linear-gradient(to bottom, rgb(83, 83, 83), rgb(43, 38, 48));
  border-radius: 50%;
  overflow: hidden;
  z-index: 2;
  transition-duration: 0.3s;
  font-size: 3vh;
}

.text-enviar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 60px;
  z-index: 1;
  transition-duration: 0.3s;
  font-size: 1.04em;
  margin-top: 1.2vh;
}

.btn-enviar:hover .icon {
  width: 100vw;
  height: 100vh;
  transition-duration: 0.3s;
}

.btn-enviar:hover .text-enviar {
  transform: translate(10px);
  width: 0;
  font-size: 0;
  transition-duration: 0.3s;
}

.btn-enviar:active {
  transform: scale(1.25);
  transition-duration: 0.3s;
}


/* reponsividade */
@media (max-width: 599px){

  .container-valores{
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  header{
    flex-direction: column;
  }

  .navbar-nav {
    display: none;
    width: 100%;
    text-align: center;
  }

  /* Mostrar o menu quando o botão de hambúrguer for clicado */
  .navbar-collapse.show .navbar-nav {
    display: block;
  }

  /* Adicionar alguma margem ao botão de hambúrguer para garantir que ele não fique colado à borda */
  .navbar-toggler {
    margin-right: 10px;
  }

  /* Melhorar a visibilidade do menu no modo colapsado */
  .nav-link {
    padding: 10px 15px;
    text-align: center;
    display: block;
  }

  .logo{
    width: 35vw !important;
  }

  .about p{
    text-align: center;
    width: 90vw;
  }

  .about img{
    display: none;
  }

  .container-equipe>.btn-23{
    text-align: center;
  }

  .container-equipe>.container{
    flex-direction: column;
    gap: 2vh;
    align-items: center;
    padding: 0 !important;
  }

  .estrutura{
    flex-direction: column !important;
  }

  .estrutura img{
    margin: 0 !important;
  }

  .container-projetos>.container{
    flex-direction: column;
    justify-content: center;
    padding-bottom: 5vh !important;
  }

  .img-estrutura,
  .img-estrutura-maior{
    width: 93vw;
  }

  .background{
    padding: 0;
    height: 20vh;
  }

  .background-2 h3{
    font-size: large !important;
  }

  .btn-23{
    text-align: center;
  }

  footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  footer p{
    display: none;
  }

  .respon{
    display: flex;
    justify-content: center;
  }

  .form{
    width: 80vw;
  }

  .btn-enviar{
    width: 60vw;
  }

  .icon{
    width: 12vw;
  }

  .container-contato{
    padding: 0;
  }

  .container-contato aside{
    display: none;
  }
}

</style>
