<template>
  <HomePage />
</template>

<script>
import HomePage from './components/HomePage.vue';

export default {
  name: 'App',
  components: {
    HomePage
  }
}
</script>

<style>

html{
  scroll-behavior: smooth;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-blue: #1D3557;      /* Azul Marinho */
  --secondary-blue: #457B9D;    /* Azul Claro */
  --accent-red: #E63946;        /* Vermelho Vibrante */
  --light-gray: #F1FAEE;        /* Cinza Claro */
  --medium-gray: #A8DADC;       /* Cinza Médio */
  --white: #FFFFFF;             /* Branco */
}

</style>
